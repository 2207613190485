import { template as template_cb5e7eecbb7d473599f2ecefb48f4b72 } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { getOwner } from '@ember/owner';
import { getStartForDancing, PlayTrack, playTrack } from '../../../../supporting/spotify';
import type { Track } from '../../../../supporting/spotify';
import type { ExerciseMediaSong } from '../../domain-objects';
import type Owner from '@ember/owner';
interface SongSignature {
    Args: {
        media: ExerciseMediaSong;
    };
}
export class Song extends Component<SongSignature> {
    playTrack = playTrack(getOwner(this) as Owner);
    play = (track: Track)=>{
        const start = this.args.media.play === 'dance' ? getStartForDancing(track) : this.args.media.play === 'custom' && this.args.media.start ? this.args.media.start * 1000 : 0;
        this.playTrack(track, start);
    };
    static{
        template_cb5e7eecbb7d473599f2ecefb48f4b72(`<PlayTrack @track={{@media.id}} @play={{this.play}} />`, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
