import { template as template_6097fa264bfc4d2fbd67d95e855314df } from "@ember/template-compiler";
import { Route } from 'ember-polaris-routing';
import CompatRoute from 'ember-polaris-routing/route/compat';
import { Page } from '@hokulea/ember';
export class TrainingPlanningPlansRoute extends Route<object> {
    static{
        template_6097fa264bfc4d2fbd67d95e855314df(`
    <Page @title="Trainingspläne">
      rr
    </Page>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
// @ts-expect-error some broken upstream types here
export default CompatRoute(TrainingPlanningPlansRoute);
