import { template as template_036f18bb26d34b3ea28e7e94fec89077 } from "@ember/template-compiler";
import { Icon } from '@hokulea/ember';
import styles from './spotify-player-warning.css';
export const SpotifyPlayerWarning = template_036f18bb26d34b3ea28e7e94fec89077(`
  <p>
    <Icon @icon="warning" @style="fill" class={{styles.warning}} />
    Bitte Spotify Player auswählen
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
