import { template as template_332f9ce778f54bb3a60231b4fc9b9f63 } from "@ember/template-compiler";
import { Route } from 'ember-polaris-routing';
import CompatRoute from 'ember-polaris-routing/route/compat';
export class ChoreographyRoutinesRoute extends Route<object> {
    static{
        template_332f9ce778f54bb3a60231b4fc9b9f63(`{{outlet}}`, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
// @ts-expect-error some broken upstream types here
export default CompatRoute(ChoreographyRoutinesRoute);
