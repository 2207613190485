import { template as template_61f33e56999040d1b5d109e0f456bdbe } from "@ember/template-compiler";
import { pageTitle } from 'ember-page-title';
import { Route } from 'ember-polaris-routing';
import CompatRoute from 'ember-polaris-routing/route/compat';
export class TrainingDiagnosticsRoute extends Route<object> {
    static{
        template_61f33e56999040d1b5d109e0f456bdbe(`
    {{pageTitle "Diagnostik"}}

    {{outlet}}
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
// @ts-expect-error some broken upstream types here
export default CompatRoute(TrainingDiagnosticsRoute);
