import { template as template_3292a1edf7374e9d87fe0b0704e31a16 } from "@ember/template-compiler";
import { pageTitle } from 'ember-page-title';
import { Route } from 'ember-polaris-routing';
import CompatRoute from 'ember-polaris-routing/route/compat';
import { Page } from '@hokulea/ember';
import { asGame, findGame, getGameTitle } from '../games';
import type { Game } from '../games';
export class GamesRoute extends Route<{
    game: Game;
}> {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    get Game() {
        return findGame(this.params.game as Game);
    }
    static{
        template_3292a1edf7374e9d87fe0b0704e31a16(`
    {{#if this.Game}}
      {{pageTitle (getGameTitle (asGame this.params.game))}}
      <Page>
        {{! @glint-ignore }}
        <this.Game />
      </Page>
    {{/if}}
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
export default CompatRoute(GamesRoute);
