import { template as template_222e27cd7e9a43f5a244471be7733cc3 } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { TimeTrackingEvaluation } from './evaluation';
import { TimelineViewer } from './viewer';
import type { YoutubePlayerAPI } from '../../../../../supporting/youtube';
import type { TimeAnalysis } from './domain';
interface TimeTrackingResultsSignature {
    Args: {
        active: boolean;
        data: TimeAnalysis;
        playerApi?: YoutubePlayerAPI;
    };
}
export class TimeTrackingResults extends Component<TimeTrackingResultsSignature> {
    player: YoutubePlayerAPI;
    static{
        template_222e27cd7e9a43f5a244471be7733cc3(`
    <TimelineViewer @active={{@active}} @playerApi={{@playerApi}} @data={{@data}} />

    <h3>Auswertung</h3>
    <TimeTrackingEvaluation @data={{@data}} />
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
