import { template as template_23fd6dff438d43dd894a795bb62c8ac7 } from "@ember/template-compiler";
import { isAuthenticated } from '../abilities';
import { LoginWithSpotify } from './login-with-spotify';
import type { TOC } from '@ember/component/template-only';
const WithSpotify: TOC<{
    Blocks: {
        default: [];
    };
}> = template_23fd6dff438d43dd894a795bb62c8ac7(`
  {{#if (isAuthenticated)}}
    {{yield}}
  {{else}}
    <LoginWithSpotify/>
  {{/if}}
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export { WithSpotify };
