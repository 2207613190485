import { template as template_b6d3e2297b504bc78f4ad51bd85c3263 } from "@ember/template-compiler";
import { cached } from '@glimmer/tracking';
import { service } from '@ember/service';
import { Route } from 'ember-polaris-routing';
import CompatRoute from 'ember-polaris-routing/route/compat';
import { use } from 'ember-resources';
import Task from 'ember-tasks';
import { Page } from '@hokulea/ember';
import { MoveTeaser } from '../-components';
import { findMoves } from '../-resource';
import type FastbootService from 'ember-cli-fastboot/services/fastboot';
export class CourseIndexRoute extends Route<{
    id: string;
}> {
    @service
    fastboot: FastbootService;
    @cached
    get load() {
        const promise = use(this, findMoves()).current;
        if (this.fastboot.isFastBoot) {
            this.fastboot.deferRendering(promise);
        }
        return Task.promise(promise);
    }
    static{
        template_b6d3e2297b504bc78f4ad51bd85c3263(`
    <Page @title="Moves" @description="Spezielle Auswahl von Bewegungen und Körpertechniken für Einradfahrer, die deiner Kür Charakter verleihen.">
      {{#let this.load as |r|}}
        {{#if r.resolved}}
          {{#each r.value as |move|}}
            <MoveTeaser @move={{move}} />
          {{/each}}
        {{/if}}
      {{/let}}
    </Page>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
export default CompatRoute(CourseIndexRoute);
