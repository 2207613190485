import { template as template_620cab23cdc04db29b9eab9a2a3aee7a } from "@ember/template-compiler";
import { ability } from 'ember-ability';
import { AudioPlayer, AudioService } from '../../audio';
import { SpotifyPlayer } from '../../spotify';
import styles from './player.css';
const isSpotifyPlayer = ability(({ service })=>()=>{
        return service(AudioService).player === AudioPlayer.Spotify;
    });
const Player = template_620cab23cdc04db29b9eab9a2a3aee7a(`
  {{#if (isSpotifyPlayer)}}
    <div class={{styles.player}} data-player>
      <SpotifyPlayer />
    </div>
  {{/if}}
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export { Player };
