import { template as template_5f5dce59f68946749b5a151b7c91b6cd } from "@ember/template-compiler";
import { pageTitle } from 'ember-page-title';
import { Route } from 'ember-polaris-routing';
import CompatRoute from 'ember-polaris-routing/route/compat';
export class MovesRootRoute extends Route<object> {
    static{
        template_5f5dce59f68946749b5a151b7c91b6cd(`
    {{pageTitle "Moves"}}

    {{outlet}}
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
// @ts-expect-error some broken upstream types here
export default CompatRoute(MovesRootRoute);
