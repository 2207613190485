const TINA_HOST = "content.tinajs.io";
class TinaClient {
  constructor({
    token,
    url,
    queries,
    errorPolicy,
    cacheDir
  }) {
    this.initialized = false;
    this.apiUrl = url;
    this.readonlyToken = token == null ? void 0 : token.trim();
    this.queries = queries(this);
    this.errorPolicy = errorPolicy || "throw";
    this.cacheDir = cacheDir || "";
  }
  async init() {
    if (this.initialized) {
      return;
    }
    try {
      if (this.cacheDir && typeof window === "undefined" && typeof require !== "undefined") {
        const { NodeCache } = await import("./node-cache-4c336858.mjs");
        this.cache = await NodeCache(this.cacheDir);
      }
    } catch (e) {
      console.error(e);
    }
    this.initialized = true;
  }
  async request({ errorPolicy, ...args }, options) {
    var _a;
    await this.init();
    const errorPolicyDefined = errorPolicy || this.errorPolicy;
    const headers = new Headers();
    if (this.readonlyToken) {
      headers.append("X-API-KEY", this.readonlyToken);
    }
    headers.append("Content-Type", "application/json");
    if (options == null ? void 0 : options.fetchOptions) {
      if ((_a = options == null ? void 0 : options.fetchOptions) == null ? void 0 : _a.headers) {
        Object.entries(options.fetchOptions.headers).forEach(([key2, value]) => {
          headers.append(key2, value);
        });
      }
    }
    const { headers: _, ...providedFetchOptions } = (options == null ? void 0 : options.fetchOptions) || {};
    const bodyString = JSON.stringify({
      query: args.query,
      variables: (args == null ? void 0 : args.variables) || {}
    });
    const url = (args == null ? void 0 : args.url) || this.apiUrl;
    const optionsObject = {
      method: "POST",
      headers,
      body: bodyString,
      redirect: "follow",
      ...providedFetchOptions
    };
    let key = "";
    if (this.cache) {
      key = this.cache.makeKey(bodyString);
      const value = await this.cache.get(key);
      if (value) {
        return value;
      }
    }
    const res = await fetch(url, optionsObject);
    if (!res.ok) {
      let additionalInfo = "";
      if (res.status === 401) {
        additionalInfo = "Please check that your client ID, URL and read only token are configured properly.";
      }
      throw new Error(
        `Server responded with status code ${res.status}, ${res.statusText}. ${additionalInfo ? additionalInfo : ""} Please see our FAQ for more information: https://tina.io/docs/errors/faq/`
      );
    }
    const json = await res.json();
    if (json.errors && errorPolicyDefined === "throw") {
      throw new Error(
        `Unable to fetch, please see our FAQ for more information: https://tina.io/docs/errors/faq/
        Errors: 
	${json.errors.map((error) => error.message).join("\n")}`
      );
    }
    const result = {
      data: json == null ? void 0 : json.data,
      errors: (json == null ? void 0 : json.errors) || null,
      query: args.query
    };
    if (this.cache) {
      await this.cache.set(key, result);
    }
    return result;
  }
}
function createClient(args) {
  const client = new TinaClient(args);
  return client;
}
export {
  TINA_HOST,
  TinaClient,
  createClient
};
