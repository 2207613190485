import { template as template_3557965db9c5414aa15eb1b834bd0675 } from "@ember/template-compiler";
import { Tag } from '../../../supporting/ui';
import styles from './styles.css';
import type { Difficulty as ExerciseDifficulty } from '../domain-objects';
import type { TOC } from '@ember/component/template-only';
const locales: Record<ExerciseDifficulty, string> = {
    beginner: 'Einsteiger',
    intermediate: 'Mittel',
    advanced: 'Fortgeschritten'
};
function getLabel(difficulty: ExerciseDifficulty) {
    return locales[difficulty];
}
interface DifficultySignature {
    Args: {
        difficulty: ExerciseDifficulty;
    };
}
const Difficulty: TOC<DifficultySignature> = template_3557965db9c5414aa15eb1b834bd0675(`
  <Tag class={{styles.tag}} data-difficulty={{@difficulty}}>{{getLabel @difficulty}}</Tag>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default Difficulty;
