import { template as template_dbd264efb64641a7a7e5e4e1bf9b4144 } from "@ember/template-compiler";
import { TinaMarkdown } from '../../../supporting/tina';
import { asTag, type Awfulpractice, type Tag } from './domain';
import styles from './styles.css';
import type { TOC } from '@ember/component/template-only';
export const TagLabel: TOC<{
    Element: HTMLSpanElement;
    Args: {
        tag: Tag;
        selected?: boolean;
    };
}> = template_dbd264efb64641a7a7e5e4e1bf9b4144(`
  <span class={{styles.tag}} data-tag={{@tag}} ...attributes>{{@tag}}</span>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export const AwfulPracticeDetails: TOC<{
    Args: {
        practice: Awfulpractice;
    };
}> = template_dbd264efb64641a7a7e5e4e1bf9b4144(`
  <details class={{styles.principle}}>
    <summary>
      <span>
        {{@practice.title}}
        <span>
          {{#each @practice.tags as |tag|}}
            <TagLabel @tag={{asTag tag}} />
          {{/each}}
        </span>
      </span>
    </summary>

    <TinaMarkdown @content={{@practice.body}} />
  </details>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
