import { template as template_b91a5b6907ad4acdb9d78470a321b6c5 } from "@ember/template-compiler";
import { isReadyForPlayback } from '../abilities';
import { SpotifyPlayerWarning } from './spotify-player-warning';
export const MaybeSpotifyPlayerWarning = template_b91a5b6907ad4acdb9d78470a321b6c5(`
  {{#unless (isReadyForPlayback)}}
    <SpotifyPlayerWarning />
  {{/unless}}
`, {
    eval () {
        return eval(arguments[0]);
    }
});
