import { template as template_26bff94f558d455db4907873526e208f } from "@ember/template-compiler";
import { Route } from 'ember-polaris-routing';
import CompatRoute from 'ember-polaris-routing/route/compat';
export class ChoreographyIndexRoute extends Route<object> {
    static{
        template_26bff94f558d455db4907873526e208f(`
    <blockquote>
      <b>Choreographie</b>
      ist die Kunst und das Handwerk Bewegungenssequenzen zu designen. In einer
      <i>Choreographie</i>
      sind Bewegungsablauf, -form oder beides
      <i>niedergeschrieben</i>.
    </blockquote>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
// @ts-expect-error some broken upstream types here
export default CompatRoute(ChoreographyIndexRoute);
