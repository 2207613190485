import { template as template_23ede9f426a7404e8250d92419411c87 } from "@ember/template-compiler";
import { element } from 'ember-element-helper';
import styles from './content.css';
import type { TOC } from '@ember/component/template-only';
import type { ComponentLike } from '@glint/template';
export interface SectionSignature {
    Element: HTMLElement;
    Args: {
        title?: string;
    };
    Blocks: {
        default: [];
        body: [];
        header: [Headline: ComponentLike<{
                Element: HTMLHeadingElement;
                Blocks: {
                    default: [];
                };
            }>];
    };
}
const or = (a: unknown, b: unknown)=>a || b;
const Section: TOC<SectionSignature> = template_23ede9f426a7404e8250d92419411c87(`
  <section class='{{styles.section}}' data-test-section ...attributes>
    {{#if (or (has-block 'header') @title)}}
      {{#let (element 'h2') as |Headline|}}
        <header data-test-section='header' part='header'>
          {{#if @title}}
            <Headline class={{styles.title}}>{{@title}}</Headline>
          {{else if (has-block 'header')}}
            {{!@glint-ignore Headline type shenanigans}}
            {{yield Headline to='header'}}
          {{/if}}
        </header>
      {{/let}}
    {{/if}}

    {{#if (has-block 'body')}}
      {{yield to='body'}}
    {{else if (has-block)}}
      {{yield}}
    {{/if}}
  </section>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default Section;
